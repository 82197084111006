import { isDocumentRtl } from '@airelogic/forms';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider, createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import rtlPlugin from 'stylis-plugin-rtl';
import { TssCacheProvider } from 'tss-react';
import App from './App';
import configureAxios from './AxiosSetup';
import ErrorBoundary from './ErrorBoundary';
import { logConsoleError } from './Services/Logging.Service';

window.onerror = logConsoleError;

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const tssCache = createCache({
  key: 'tss',
  stylisPlugins: isDocumentRtl() ? [rtlPlugin] : [],
});

if (isDocumentRtl()) {
  document.dir = 'rtl';
}

configureAxios();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false, //we have axios retry for transient errors
      staleTime: 0,
      gcTime: 0, //Keep at 0 to globally disable cache. We can enable it inline as needed
    },
  },
});

const rootElement = document.getElementById('root') as HTMLElement;
Modal.setAppElement(rootElement);

createRoot(rootElement).render(
  <StrictMode>
    <ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CacheProvider value={muiCache}>
          <TssCacheProvider value={tssCache}>
            <ThemeProvider theme={createTheme()}>
              <App />
            </ThemeProvider>
          </TssCacheProvider>
        </CacheProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </StrictMode>,
);
